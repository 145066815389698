import React, { useState } from 'react';
import TeamService from "./team.service";

import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';

export default function GetListOfTeams(props) {

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            maxWidth: 752,
        },
        demo: {
            //backgroundColor: theme.palette.background.paper,
        },
        title: {
            //margin: theme.spacing(4, 0, 2),
        },
    }));

    const [teamList, setTeamList] = useState([]);

    const getTeamsList = async (teamId) => {

        await TeamService.getTeams().then(
            response => {
                setTeamList(response.data.team);
            },
            error => {

                setTeamList([]);
            }
        );


    }
    const selectTeam = function (teamId, e) {

        if (teamId === -1) {
            props.action({ teamId: -1, refresh: true, nextAction: 'createTeam' });
        }
        else if (teamId >= 0) {
            teamList.forEach(x => {
                if (x.teamId === teamId) {
                    props.action({ teamId: teamId, refresh: false, nextAction: 'memberList', teamDescription: x.teamDescription, teamName: x.teamName });
                }
            })

        }
    }

    const editTeam = function (teamId, e) {
        //console.log('edit team:' + teamId);
        teamList.forEach(x => {
            if (x.teamId === teamId) {
                props.action({ teamId: teamId, refresh: false, nextAction: 'editTeam', teamDescription: x.teamDescription, teamName: x.teamName });
            }
        })

    }

    const deleteTeam = function (teamId, e) {
        TeamService.deleteTeam(teamId).then(
            response => {
                props.action({ teamId: -1, refresh: true, nextAction: 'listTeam' });
            }
            ,
            error => {
             
                setTeamList([]);

            });
    }

    const createNewTeam = function () {
        props.action({ teamId: -1, refreshToken: false, nextAction: 'createTeam' });
    }

    const getDate = function (creationDate) {
        const meses = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let data = new Date(creationDate);
        let dataFormatada = ((data.getDate() + " " + meses[(data.getMonth())] + " " + data.getFullYear()));
        return dataFormatada;
    }

    React.useEffect(() => {
        getTeamsList();
    }, [props.refreshToken])

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }
    const classes = useStyles();
    //const [dense, setDense] = React.useState(false);
    const dense = false;

    return (
        <div>
            <div className="row">
                <div className="col-md-6 categoryDisplay">
                    <h4 className="">My Groups </h4>
                </div>

                <div className="col-md-6 categoryDisplay text-right">
                    <Button onClick={(e) => createNewTeam()}>
                        <Icon className="addButton">add_circle</Icon>
                        <div>
                            new group
                        </div>
                    </Button>

                </div>
            </div>

            <div className="">
                <Grid item xs={12} md={8}>
                    <div className={classes.demo}>
                        <List dense={dense}>
                            {teamList && teamList.length >= 1 && teamList.map((team) =>
                                <div key={team.teamId}>
                                    <ListItem key={team.teamId} className="itemRoundedCorners">
                                  
                                        <ListItemLink onClick={(e) => selectTeam(team.teamId, e)}>
                                            <ListItemText
                                                primary={team.teamName}
                                                secondary={team.teamDescription ? team.teamDescription + ' - ' + getDate(team.teamCreationDate) : null}
                                            />
                                        </ListItemLink>
                                        <ListItemSecondaryAction>

                                            <IconButton edge="end" aria-label="edit team" onClick={(e) => { editTeam(team.teamId) }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton edge="end" aria-label="delete" onClick={(e) => { if (window.confirm('This cannot be undone and will delete everyone on the team.\nAre you sure you wish to delete this team?')) deleteTeam(team.teamId) }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </div>,

                            )}
                        </List>
                    </div>
                </Grid>
            </div>

        </div>
    );
}