import React from 'react';
import './Calendar.css';

const Calendar = ({ year, moodData }) => {

  const months = Array.from({ length: 12 }, (_, i) => i);
  const days = Array.from({ length: 31 }, (_, i) => i + 1);

  const calculateTotal = (month) => {

    const totalMoods = moodData.reduce((acc, entry) => {
      const entryDate = new Date(entry.date);
      if (entryDate.getFullYear() === year && entryDate.getMonth() === month) {
        acc += 1; // Count each mood entry
      }
      return acc;
    }, 0);

    return `${totalMoods}`;
  };

  const calculateMedianMood = (month) => {

    const moods = moodData
      .filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate.getFullYear() === year && entryDate.getMonth() === month;
      })
      .map(entry => entry.mood)
      .sort((a, b) => a - b);

    if (moods.length === 0) return 'N/A';

    const mid = Math.floor(moods.length / 2);
    const median = moods.length % 2 !== 0 ? moods[mid] : Math.round((moods[mid - 1] + moods[mid]) / 2);

    //return getMoodEmoji(median); // Convert the median mood to an emoji
    return getMoodContent(median);
  };


  return (
    <div className="calendar-container">

      <div className="calendar">
        <div className="days-column">
          <div className="days-header">Day</div>
          {days.map(day => (
            <div key={day} className="day-number">{day}</div>
          ))}
          {/* <div className="interval-row"></div> */}
          {/* <div className="total-row-header">Feedback</div> */}
        </div>
        {months.map(month => (
          <div key={month} className="month-column">
            <div className="month-title">
              {new Date(year, month).toLocaleString('default', { month: 'short' })}
            </div>
            {days.map(day => (
              <Day key={`${month}-${day}`} day={day} month={month} year={year} moodData={moodData} />
            ))}
            {/* <div className="interval-row"></div> */}
            {/* <div className="total-row">{calculateTotal(month)} - {calculateMedianMood(month)}</div> */}
          </div>
        ))}
      </div>

      <div className="interval-row"></div>
      <div className="calendar">
        {/* Number of moods per month */}
        <div className="days-column">
          <div title="Number of submitted moods per month" className="total-row-header">#</div>
        </div>
        {months.map(month => (
          <div key={month} className="month-column">
            <div className="total-row">{calculateTotal(month)}</div>
          </div>
        ))}
        
        {/* Average per month */}
        <div className="days-column">
          <div title="Average mood per month" className="total-row-header">Avg</div>
        </div>
        {months.map(month => (
          <div key={month} className="month-column">
            <div className="total-row">{calculateMedianMood(month)}</div>
          </div>
        ))}

      </div>

    </div>
  );
};

const Day = ({ day, month, year, moodData }) => {
  const date = new Date(year, month, day);
  const isWeekend = date.getDay() === 0 || date.getDay() === 6;
  const isValidDay = day <= new Date(year, month + 1, 0).getDate();

  const moodForDay = moodData.find(moodEntry => {
    const entryDate = new Date(moodEntry.date);
    return entryDate.getFullYear() === year && entryDate.getMonth() === month && entryDate.getDate() === day;
  });

  const moodClass = moodForDay ? getMoodClass(moodForDay.mood) : '';

  return (
    <div className={`day-cell ${moodClass} ${isWeekend ? 'weekend' : ''} ${isValidDay ? '' : 'invalid-day'}`}>
      <div className="day-content">
        {/* //Testing not having the icons */}
        {isValidDay ? (moodForDay ? getMoodContent(moodForDay.mood) : '') : ''}
      </div>
    </div>
  );
};

const getMoodClass = (mood) => {
  switch (mood) {
    case 0: return 'very-unpleasant';
    case 1: return 'unhappy';
    case 2: return 'ok';
    case 3: return 'happy';
    case 4: return 'awesome';
    default: return '';
  }
};
const getMoodContent = (mood) => {
  switch (mood) {
    case 0: return <img src='/svg/mad.svg?ts=20250304' alt='mad' className='myYearInReviewIcon'></img>; // very unpleasant
    case 1: return <img src='/svg/not%20happy.svg?ts=20250304' alt='not that happy' className='myYearInReviewIcon'></img>; // unhappy
    case 2: return <img src='/svg/medium.svg?ts=20250304' alt='medium' className='myYearInReviewIcon'></img>; // ok
    case 3: return <img src='/svg/happy.svg?ts=20250304' alt='happy' className='myYearInReviewIcon'></img>; // happy
    case 4: return <img src='/svg/very%20happy.svg?ts=20250304' alt='very happy' className='myYearInReviewIcon'></img>; // awesome
    default: return '';
  }
};

export default Calendar;