import React, { Component } from "react";
import './FeedComponent.css';
import FeedComponentService from "./FeedComponent.service";

export default class FeedComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamid: props.teamid,
            feed: [
            ]
        };
    }

    updateFeed() {
        FeedComponentService.getTeamFeedback(this.state.teamid).then(
            response => {
                this.setState({ feed: response.data });
            },
            error => {
            });
    }
    componentDidMount() {
        setInterval(() => this.updateFeed(), 5000);
        this.updateFeed();
    }

    getDate(x) {
        const unixTime = x;
        const date = new Date(unixTime);
        //return date.toLocaleDateString("pt-PT") + ' ' + date.toLocaleTimeString("pt-PT");
        return date.toLocaleTimeString("pt-PT");
    }
    getSimpleDate(x) {
        const unixTime = x;
        const date = new Date(unixTime);
        return date.toLocaleDateString("pt-PT");
    }

    /**
     * Create HTML element that map with Giphy
     * @param {extension from the array} extension Extension from array, structure is name, url
     * @returns An array to populate the giphy images
     */
    getGiphyIframe(extension) {
        return <div className="embeddedGiphy" dangerouslySetInnerHTML={{ __html: "<iframe frameBorder='0' allowFullScreen src='" + extension + "'}' />" }} />
    }

    render() {
        return (
            <div className="feedback">
                <div className="title">GROUP FEED:</div>
                <div>
                    {this.state.feed.map((feed, i) =>
                        <div key={'feed' + i} className="feed">

                            {((i > 0 && this.getSimpleDate(this.state.feed[i - 1].recordTime) !== this.getSimpleDate(feed.recordTime)) || i === 0) &&
                                <div>{this.getSimpleDate(feed.recordTime)}</div>
                            }

                            <div className="feedback">
                                <div className="name">{feed.sessionName}:</div>
                                <div className="fb">
                                    <div>{feed.feedbackComment}</div>

                                </div>
                                <div className="ts">{this.getDate(feed.recordTime)}</div>
                            </div>

                            {feed.extensionAddress &&
                                <div className="extension">
                                    <div className="name">
                                        <div className="text">
                                            GIF
                                        </div>
                                    </div>
                                    <div className="content">{this.getGiphyIframe(feed.extensionAddress)}</div>
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>
        )
    }
}