import React, { Component } from "react";
import { TextField, Button } from "@mui/material";
import FeedComponentService from "./FeedComponent.service";
import './FeedbackComponent.css';
import GiphySelector from "../components/GiphySelector.js";
import { Gif } from '@giphy/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons'


export default class FeedbackComponent extends Component {
    constructor(props) {
        super(props);
        this.handleComment = this.handleComment.bind(this);
        this.state = {
            teamid: props.teamid,
            feedback: '',
            giphyAddress: '',
            showGiphyWindow: false,
            gif: undefined
        }

    }

    /**
     * Returns a random string with a silly mesage
     * @returns String
     */
    getRandomMessage() {
        const randomToughts = [
            'Share something with the group... Are you feeling killing instincts today?',
            'Share with the group... How good is your life?',
            'Are you doing enough exercise to improve your well-being and release some stress?',
            'Do you believe in yourself?',
            'Have you started a random conversation?',
            'Are you having enough rest?',
            'How is everything going on work?',
            'Let us know your inner thoughts...',
            'Reflect on your accomplishments... Are you proud of the person you\'ve become?',
            'Share your favorite self-care routine... How do you prioritize your well-being?',
            'Take a moment to appreciate your progress... How far have you come in your personal growth?',
            'Have you embraced vulnerability lately? Share an experience that made you feel truly alive.',
            'Are you actively seeking new knowledge? Share the last book that inspired you.',
            'Let go of self-doubt... Do you truly believe in your own potential?',
            'Share a small act of kindness you\'ve done recently... How did it make you feel?',
            'Are you nurturing your passions? Share something that brings you immense joy.',
            'Reflect on your relationships... Are you surrounding yourself with supportive and uplifting individuals?',
            'Share a positive affirmation that helps you stay focused and motivated.',
            'Take a moment to appreciate the beauty around you... What\'s one thing you\'re grateful for today?',
            'Have you recently stepped out of your comfort zone? Share an experience that pushed your boundaries.',
            'Share a mindful practice you engage in to stay grounded and centered.',
            'How do you prioritize self-care in your daily routine? Share your go-to self-care activity.',
            'Reflect on your personal values... Are you living in alignment with what truly matters to you?',
            'Share a mantra or quote that resonates with your journey of self-discovery.',
            'Are you actively practicing self-compassion? Share a time when you showed kindness to yourself.',
            'Reflect on your goals and aspirations... Are you taking consistent steps toward achieving them?',
            'Share a lesson you\'ve learned recently that has helped you grow as a person.',
            'Take a moment to appreciate your unique qualities... What makes you truly authentic?'
        ];
        return randomToughts[Math.floor(Math.random() * (randomToughts.length - 1 + 1) + 1) - 1]
    }

    /**
     * Stores comment on the textbox
     * @param {*} e 
     */
    handleComment(e) {
        this.setState({
            feedback: e.target.value
        });
    }

    /**
     * Register team feedback mood
     */
    sendMood() {
        this.setState({ loading: true });
        if (this.state.feedback !== '' || this.state.giphyAddress !== '') {
            FeedComponentService.registerFeedback(this.state.teamid, this.state.feedback, this.state.giphyAddress).then(
                response => {
                    this.setState({ feedback: '' });
                },
                error => {
                    this.setState({ feedback: '' });
                }
            )
        }
        this.clearGif();
        this.setState({ loading: false });
    }

    /**
     * Open the window to choose the Giphy
     */
    openGifWindow() {
        this.setState({ showGiphyWindow: true });
    }

    /**
     * Defines the 
     */
    selectedGif(gif) {
        this.setState({ gif: gif, giphyAddress: gif.embed_url });
    }

    clearGif() {
        this.setState({ gif: null, giphyAddress: '' });
    }

    closeSearchGiphy() {
        this.setState({ showGiphyWindow: false });
    }



    render() {
        const { showGiphyWindow } = this.state;

        return (
            <div className="feedback ">
                <div className="title">GROUP FEEDBACK:</div>
                <div className="feedbackArea">
                    <TextField
                        variant="standard"
                        className="textBox"
                        placeholder={this.getRandomMessage()}
                        fullWidth
                        InputProps={{
                            disableUnderline: true,
                            maxLength: 255,
                        }}
                        margin="normal"
                        onChange={this.handleComment}
                        multiline
                        maxRows={7}
                        value={this.state.feedback}
                        InputLabelProps={{
                            shrink: true
                        }}></TextField>
                    <div className="extension">
                        <div className="name">
                            <div className="text" onClick={() => this.openGifWindow()}>
                                GIF
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.gif && (
                    <div className="extensionContent">
                        <div className="columnMain">
                            <Gif gif={this.state.gif} width={200} />
                        </div>
                        <div className="columnSide">
                            <div onClick={() => this.clearGif()}>
                                <FontAwesomeIcon icon={faTrash} />
                            </div>
                        </div>
                    </div>
                )}

                <GiphySelector
                    showGiphyWindow={showGiphyWindow}
                    closeWindow={() => this.closeSearchGiphy()}
                    selectedGif={(gif) => this.selectedGif(gif)} />

                <div>
                    <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        size="medium"
                        disabled={this.state.loading}
                        onClick={() => this.sendMood()}>
                        Send
                    </Button>
                </div>
            </div>
        )
    }
}