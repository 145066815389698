import React, { useState, useEffect } from "react";
import TeamService from "./team.service.js";
import './Team.css';

import MoodService from "../mood/mood.service";
import FeedComponent from "./FeedComponent.js";
import FeedbackComponent from "./FeedbackComponent.js";
import MembersComponent from "./MembersComponent.js";
import Icon from '@mui/material/Icon';
import Button from '@mui/material/Button';
import AuthService from "../login/auth.service";
import { useParams } from 'react-router-dom';
import TeamWidget from "../mood/TeamWidget.js";

const Dashboard = (props) => {
    const [state, setState] = useState({
        teamid: props.teamid,
        members: [],
        available: false,
        data: null,
        moderatorProfile: false
    });

    const { teamid } = useParams(); // If you're using routing parameters

    useEffect(() => {
        if (!AuthService.isSessionValid()) return;

        const user = AuthService.getCurrentUser();
        setState(prevState => ({
            ...prevState,
            moderatorProfile: user.roles.includes("ROLE_MODERATOR"),
            teamid: teamid // Or props.match.params.teamid if you're not using useParams
        }));

        TeamService.getMembers(teamid).then(response => {
            if (response.data === '') {
                setState(prevState => ({ ...prevState, available: false }));
                return;
            }

            setState(prevState => ({
                ...prevState,
                available: true,
                name: response.data.members.teamName,
                description: response.data.members.teamDescription,
                creationDate: response.data.members.teamCreationDate,
                members: response.data.members.teamUserList
            }));

            MoodService.getTeamLastWeekMood(teamid).then(
                response => {
                    setState(prevState => ({ ...prevState, data: response.data }));
                },
                error => {
                    // handle error
                }
            );
        }, error => {
            // handle error
        });
    }, [teamid]);

    const manageTeams = () => {
        window.location.pathname = '/manageteams';
    };

    // const getDate = (creationDate) => {
    //     const meses = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    //     let data = new Date(creationDate);
    //     return `${data.getDate()} ${meses[data.getMonth()]} ${data.getFullYear()}`;
    // };

    const { available, name, members } = state;
    return (
        <div>
            <div>
                <div className="categoryDisplay text-right">
                    {state.moderatorProfile &&
                        <Button onClick={(e) => manageTeams()}>
                            <Icon className="addButton">manage_accounts</Icon>
                            <div>
                                manage group
                            </div>
                        </Button>
                    }
                </div>
            </div>

            {available &&
                <div className="mainContainer">

                    <div className="column">
                        <div className="widget">

                            <TeamWidget teamid={teamid} teammembers={members.length} teamname={name} showdetails={false} />
                        </div>

                        <div>&nbsp;</div>

                        <div className="widget">
                            {/* <MembersComponent membersList={members} /> */}
                            <FeedbackComponent teamid={teamid} />
                        </div>
                    </div>

                    <div className="column">
                        <div className="widgetXX">
                            <MembersComponent membersList={members} />
                            {/* <FeedbackComponent teamid={teamid} /> */}
                        </div>

                        <div>&nbsp;</div>

                        <div className="widgetXX">
                            <FeedComponent teamid={teamid} />
                        </div>
                    </div>
                </div>
            }

            {!available &&
                <div>Record not found</div>
            }        </div>
    );
};

export default Dashboard;
