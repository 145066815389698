import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../login/auth.service";
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons'


import "./header.css";

const Header = (props) => {

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const { pathname } = useLocation();

    function logOut() {
        AuthService.logout();
    }

    return (

        <div className="header">

            <div className="logo-nav">
                <div className="logo-container">
                    <Link to={"/mydashboard"} target="_parent" className="navbar-brand cranky-brand">
                        <img alt="logo" src="/favicon-32x32.png"></img><strong>CrankyDino<sup>beta</sup></strong>
                    </Link>
                </div>

                <ul className={click ? "nav-options active nav-menu-widget" : "nav-options"}>
                    {props.currentUser && (
                        <li className={`option  cranky-item ${pathname === '/user' ? "cranky-item-strong" : ""}`} onClick={closeMobileMenu}>
                            <Link to={"/user"} className="nav-link">
                                My mood
                            </Link>
                        </li>)}
                    {props.currentUser && (
                        <li className={`option  cranky-item ${pathname === '/mydashboard' ? "cranky-item-strong" : ""}`} onClick={closeMobileMenu}>
                            <Link to={"/mydashboard"} className="nav-link">
                                My dashboard
                            </Link>
                        </li>)}


                    {props.currentUser && (
                        <li className={`option  cranky-item ${(pathname === '/dashboard' || pathname === '/manageteams' || pathname.includes('/team')) ? "cranky-item-strong" : ""}`} onClick={closeMobileMenu}>
                            <Link to={"/dashboard"} className="nav-link">
                                Group dashboard
                            </Link>
                        </li>
                    )}

                    {props.currentUser ? (
                        <div className="">
                            <li className={`option mobile-option cranky-item`} onClick={closeMobileMenu}>
                                <a href={"https://buymeacoffee.com/crankydino"} rel="noreferrer" target='_blank' className="nav-link">
                                    ❤️ Support Us
                                </a>
                            </li>
                            <li className={`option mobile-option cranky-item ${pathname.includes('/profile') ? "cranky-item-strong" : ""}`} onClick={closeMobileMenu}>
                                <Link to={"/profile"} className="nav-link">
                                    <FontAwesomeIcon icon={faGear} /> {props.currentUser.name}
                                </Link>
                            </li>
                            <li className="cranky-item-line" />

                            <li className="nav-item mobile-option option cranky-item" onClick={closeMobileMenu}>
                                <a href="/login" className="nav-link" onClick={logOut}>
                                    Log Out
                                </a>
                            </li>
                        </div>
                    )
                        : (
                            <>
                                <li className="option mobile-option cranky-item" onClick={closeMobileMenu}>
                                    <Link to={"/login"} className="nav-link">
                                        Log In
                                    </Link>
                                </li>
                                <li className="option mobile-option cranky-item" onClick={closeMobileMenu}>
                                    <Link to={"/register"} className="nav-link">
                                        Sign Up
                                    </Link>
                                </li>
                            </>
                        )}

                </ul>
            </div>

            <div className={click ? "mobile-menu-container change" : "mobile-menu-container"} onClick={handleClick}>
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
            </div>

            {props.currentUser ? (
                <ul className="signin-up">
                    <li className={`cranky-item`} onClick={closeMobileMenu}>
                        <a href="https://buymeacoffee.com/crankydino" rel="noreferrer" target="_blank" className="nav-link">
                            ❤️ Support Us
                        </a>
                    </li>
                    <li className={`cranky-item ${pathname.includes('/profile') ? "cranky-item-strong" : ""}`} onClick={closeMobileMenu}>
                        <Link to={"/profile"} className="nav-link">
                            <FontAwesomeIcon icon={faGear} /> {props.currentUser.name}
                        </Link>
                    </li>
                    <li className="cranky-item" onClick={closeMobileMenu}>
                        <a href="/login" className="nav-link" onClick={logOut}>
                            Log Out
                        </a>
                    </li>
                </ul>
            ) : (
                <ul className="signin-up">
                    <li className="cranky-item" onClick={closeMobileMenu}>
                        <Link to={"/login"} className="nav-link">
                            Log In
                        </Link>
                    </li>
                    <li className="cranky-item" onClick={closeMobileMenu}>
                        <Link to={"/register"} className="nav-link">
                            Sign Up
                        </Link>
                    </li>
                </ul>
            )}

        </div >
    );
};

export default Header;