import React, { useState, useEffect } from 'react';

import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import TimerIcon from '@mui/icons-material/Timer';
import FaceIcon from '@mui/icons-material/Face';
import WhatshotIcon from '@mui/icons-material/Whatshot';

import TeamService from "./team.service";
import InviteService from "./invite.service";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AddTeamMember from './AddTeamMember';


export default function TeamMembersList(props) {

    
    const [members, setMembers] = useState([]);

    const deleteMember = function (value, type) {

        // search for index to remove
        members.forEach((x, index) => {
            if (x.id === value && x.type === type) {
                members.splice(index, 1);
            }
        });

        setMembers([].concat(members));
    }

    const getServiceTeamMembers = async (teamId) => {

        await TeamService.getTeamMembers(teamId)
            .then(response => {
                response.data.team.members.map(x => x.type = 'member');
                response.data.team.invitedMembers.map(x => x.type = 'invited');
                setMembers([].concat(response.data.team.members, response.data.team.invitedMembers));
            },
                error => {
                    // this.setState({
                    //     message: (error.response &&
                    //         error.response.data &&
                    //         error.response.data.message) ||
                    //         error.message ||
                    //         error.toString()
                    // });
                    setMembers([]);
                });
    }

    const inviteNewTeamMember = function (name,email) {
        var newElement = [].concat(members, { id: Date.now(), type: 'pending', name, email });
        setMembers(newElement);
    }

    const cancelChanges = function (e) {
        props.action({ teamId: -1, refresh: true, nextAction: 'listTeams' });
    }

    const saveChanges = async (e) => {
        var body = { teamId: props.teamId, memberList: members };
        await InviteService.inviteTeamMembers(body)
            .then(response => {
                getServiceTeamMembers(props.teamId);
            },
                error => {
                    // this.setState({
                    //     message: (error.response &&
                    //         error.response.data &&
                    //         error.response.data.message) ||
                    //         error.message ||
                    //         error.toString()
                    // });
                    //setMembers([]);
                });

        //props.action({ teamId: -1, refresh: true, nextAction: 'listTeams' });
    }

    // function ListItemLink(props) {
    //     return <ListItem button component="a" {...props} />;
    // }

    useEffect(() => {
        getServiceTeamMembers(props.teamId);
    }, [props.teamId])

    return (
        <div className="">

            <div className="categoryDisplay">
                <h4>{props.teamName}</h4>
            </div>

            <div className="itemSpacing">
                <div>
                    <div className="row">
                        <div className="col col-md-6">
                            <h4>Group Members ({members.length})</h4>
                        </div>
                        <div className="col col-md-6 text-right">
                            <AddTeamMember event={inviteNewTeamMember}/>
                        </div>
                    </div>

                </div>
                <div><h2>Members of the group</h2></div>
                <div>
                    <List dense={true}>
                        {members && members.length >= 1 && members.filter(function (member) { return member.type === "member"; }).map((member) =>
                            <div key={member.id}>
                                <ListItem key={member.id} className="itemRoundedCorners">
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FaceIcon />
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary={member.name}
                                        secondary={member.email}
                                    />

                                    <ListItemSecondaryAction>

                                        <IconButton edge="end" aria-label="delete" onClick={(e) => { if (window.confirm('This cannot be undone and will delete the person from the list.\nAre you sure you wish to delete this person?')) deleteMember(member.id, 'member') }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>

                            </div>,

                        )}
                    </List>
                </div>
                <div><h2>Invited members</h2></div>
                <div>
                    <List dense={true}>
                        {members && members.length >= 1 && members.filter(function (member) { return member.type !== "member"; }).map((member) =>
                            <div key={member.id}>
                                <ListItem key={member.id} className="itemRoundedCorners">
                                    <ListItemAvatar>
                                        <Avatar>
                                            {member.type === "invited" ? <TimerIcon /> : <WhatshotIcon />}
                                        </Avatar>
                                    </ListItemAvatar>

                                    <ListItemText
                                        primary={member.name}
                                        secondary={member.email}
                                    />

                                    <ListItemSecondaryAction>

                                        <IconButton edge="end" aria-label="delete" onClick={(e) => { if (window.confirm('This cannot be undone and will delete the person from the list.\nAre you sure you wish to delete this person?')) deleteMember(member.id, 'invited') }}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>,

                        )}

                    </List>
                </div>
            </div>

            <div className="editRowContainer">

                <div className="editTeamRowItem">
                    <Button variant="contained" onClick={(e) => cancelChanges()}>Cancel Changes</Button>
                </div>
                <div className="editTeamRowItem">
                    <Button variant="contained" color="primary" onClick={(e) => saveChanges()}>Save Changes</Button>
                </div>
            </div>


        </div>

    );
}